import React, { useState, useEffect } from "react";
import editicon from "../../../../assets/images/icons/edit.svg";
import removeicon from "../../../../assets/images/icons/remove.svg";
import searchicon from "../../../../assets/images/icons/search-normal.svg";
import { Table } from "reactstrap";
import IconButton from "../../../../assets/elements/IconButton";
import Pagination from "../../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import SuccessModal from "../../../../assets/elements/SuccessModal";
import "../DepartmentMaster.scss";
import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import { PrimaryInput } from "../../../../assets/FormElements";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../../assets/images/icons/icons";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import MainServices from "../../../../services/MainServices";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import moment from "moment";
import PageLoader from "../../../../assets/loaders/PageLoading";
import { useLocation, useNavigate } from "react-router-dom";
import TimingFunctions from "../../../../assets/functions/TimingFunctions";
import PermissionFunctions from "../../../../assets/functions/PermissionFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../../assets/functions/filters";
import useFeatureSetting from "../../../../hooks/useFeatureSetting";
import StatusModalContent from "../../../../assets/modal/StatusModalContent";
import editgreenicon from "../../../../assets/images/icons/edit1.svg";
import useAuth from "../../../../hooks/useAuth";
import ViewIcon from "../../../../assets/images/icons/eye-svgrepo-com.svg";
import Cross from "../../../../assets/images/icons/cross.svg";
import IsWorkflowSlug from "../../../../assets/modal/IsWorkflowSlug";

const StatusEnum = {
  INITIATED: "initiated",
  REINITIATED: "reinitiated",
  APPROVED: "approved",
  REJECTED: "rejected",
};

const allStatus = [
  { label: "All", value: "" },
  { value: StatusEnum.INITIATED, label: "Initiated" },
  { value: StatusEnum.REINITIATED, label: "Reinitiated" },
  { value: StatusEnum.APPROVED, label: "Approved" },
  { value: StatusEnum.REJECTED, label: "Rejected" },
];

const BranchMaster = () => {
  const [successModal, setSuccessModal] = useState();
  const [successType, setSuccessType] = useState();
  const [addBranchModal, setAddBranchModal] = useState(false);
  const [deleteBranchModal, setDeleteBranchModal] = useState(false);
  const { updateCurrentPage } = useCurrentPage();
  const {
    getBranchMasters,
    addBranchMaster,
    updateBranchMaster,
    deleteBranchMaster,
    getWorkFlowsByType,
    getWorkflowsBySlug,
  } = MainServices();
  const [pageLoading, setPageLoading] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(0);
  const [totalBranches, setTotalBranches] = useState(0);
  const [branchMasters, setBranchMasters] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({});
  const { updateSettingContext, setting } = useFeatureSetting();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [selectedStatus, setSelectedStatus] = useState("All");
  const location = useLocation();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();
  const { grantPermission } = PermissionFunctions();
  const [statusModal, setStatusModal] = useState(false);
  const { user } = useAuth();
  const workflowId = new URLSearchParams(location.search).get("workflowId");
  const [sopWorkFlows, setSopWorkFlows] = useState([]);
  const [intiLoading, setIntiLoading] = useState(0);
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [branches, setBranches] = useState([]);
  const [isWorkflowFlag, setIsWorkflowFlag] = useState({});
  const [workflowModal, setWorkflowModal] = useState(false);
  const [workflowDataSet, setWorkflowDataSet] = useState("");

  const branchSchema = Yup.object().shape({
    branch_name: Yup.string().transform((value) => value.trim()).required("Branch Name is Required"),
    branch_description: Yup.string().transform((value) => value.trim()).required("Branch Description is Required"),
    branch_code: Yup.string().transform((value) => value.trim()).required("Branch Code is Required"),
  });

  const updateBranchSchema = Yup.object().shape({
    branch_name: Yup.string().transform((value) => value.trim()).required("Branch Name is Required"),
    branch_description: Yup.string().transform((value) => value.trim()).required("Branch Description is Required"),
    branch_code: Yup.string().transform((value) => value.trim()).required("Branch Code is Required"),
    password: Yup.string().transform((value) => value.trim()).required("Password is Required"),
  });

  const deleteSchema = Yup.object().shape({
    password: Yup.string().transform((value) => value.trim()).required("Password is Required"),
  });

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    updateCurrentPage({
      title: "Master",
      subtitle: "Branches",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSettingContext("branches");
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);

    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 10,
    });

    getBranchMasterByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 10,
      filters,
    });
    getIsWorkflowSlug();
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  //apis
  const getBranchMasterByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      sorting: [{ key: "id", direction: "desc" }],
    };
    await axios
      .all([getBranchMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let total = resData?.totalCount || 0;
            setTotalBranches(total);
            let branches = resData.data ? resData.data : [];
            branches = branches.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
              };
            });
            setBranchMasters(branches);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Branches");
      });
  };

  const addBranch = async (values) => {
    setButtonLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      branchName: values.branch_name,
      branchDescription: values.branch_description,
      branchCode: values.branch_code,
      status: "active",
      ...(values?.password && { password: values?.password }),
    };
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([addBranchMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            getBranchMasterByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            onAddBranch();
            notifySuccess("Branch Master Added Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Branch Master");
      });
  };

  const updateBranch = async (values) => {
    setButtonLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      branchName: values.branch_name,
      branchDescription: values.branch_description,
      branchCode: values.branch_code,
      ...(values?.password && { password: values?.password }),
    };
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([
        updateBranchMaster(
          details,
          selectedBranch.publicId,
          selectedBranch.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getBranchMasterByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            onAddBranch();
            notifySuccess("Branch Master Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Branch Master");
      });
  };

  const onBranchStatusChange = async (
    { status, email, password },
    isStatus
  ) => {
    setButtonLoading(true);
    const details = {
      status: status.value ? status.value : status,
      // email,
      ...(password && { password: password }),
    };
    await axios
      .all([
        deleteBranchMaster(
          details,
          selectedBranch.publicId,
          selectedBranch.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getBranchMasterByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            setStatusModal(false);
            setDeleteBranchModal(false);
            notifySuccess("Status updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in Status Update Master");
      });
  };

  const getIsWorkflowSlug = async () => {
    const details = {};
    await axios
      .all([getWorkflowsBySlug(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let isWorkflow = resData?.data ? resData?.data : {};
            isWorkflow = isWorkflow?.content
              ? JSON.parse(isWorkflow?.content)
              : {};
            setIsWorkflowFlag(isWorkflow);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting measurement types");
      });
  };

  //functions
  const onManageWorkflow = ({ values, setFieldValue }) => {
    setWorkflowDataSet({
      workflows: values?.workflows,
      setFieldValue,
    });
    setWorkflowModal((prev) => !prev);
  };

  const onStatusModal = (selected) => {
    if (selected) {
      setSelectedBranch(selected);
    } else {
      setSelectedBranch("");
    }
    setStatusModal(!statusModal);
  };

  const onAddBranch = (selected) => {
    if (selected) {
      setSelectedBranch(selected);
    } else {
      setSelectedBranch("");
    }
    setAddBranchModal(!addBranchModal);
  };

  const onDeleteBranch = (selected) => {
    if (selected) {
      setSelectedBranch(selected);
    } else {
      setSelectedBranch("");
    }
    setDeleteBranchModal(!deleteBranchModal);
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchDepartment = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      branchName: { branchName: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 10,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  return (
    <div className="admin-popular-main">
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}
        message="Branch Service"
        type={successType}
      />

      <div className="header">
        <p className="content-header-main">
          Branches {pageLoading ? "" : `(${totalBranches || 0})`}
        </p>

        <div className="d-flex gap-3">
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchDepartment(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex">
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
              style={{ width: "100px" }}
            >
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {grantPermission("Branch_write") && (
            <IconButton
              iconType="add"
              title="Add Branch"
              onClick={() => onAddBranch("")}
            />
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : branchMasters && branchMasters.length > 0 ? (
        <div className="content">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>#</th>
                  <th>Branch Name</th>
                  <th>Branch Code</th>
                  <th>Created Date</th>
                  <th>Status</th>
                  {(grantPermission("Branch_delete") ||
                    grantPermission("Branch_update")) && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {branchMasters.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td>{each?.branchName}</td>
                      <td>{each?.branchCode}</td>
                      <td>{moment(each?.created_date).format("DD-MM-YYYY")}</td>
                      <td>{each?.status}</td>
                      {(grantPermission("Branch_delete") ||
                        grantPermission("Branch_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("Branch_read") && (
                              <div
                                onClick={() =>
                                  navigate(
                                    `/branch/details?publicId=${each.publicId}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={ViewIcon}
                                  alt="View icon"
                                  width="20px"
                                  height="20px"
                                />
                              </div>
                            )}
                            {grantPermission("Branch_update") &&
                              each?.status !== "inactive" && (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/branch/details?publicId=${each.publicId}&edit=true`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}
                            {grantPermission("Branch_delete") &&
                              each?.status !== "inactive" && (
                                <div
                                  onClick={() => onDeleteBranch(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}
                            {/* {grantPermission("Branch_update") &&
                              setting.update_status &&
                              each.status !== setting.approval_status && (
                                <div
                                  onClick={() => onStatusModal(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editgreenicon} alt="edit icon" />
                                </div>
                              )} */}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No Branches Found</div>
      )}
      {branchMasters && branchMasters.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalBranches}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        show={addBranchModal}
        onHide={onAddBranch}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {selectedBranch ? "Update Branch" : "Add Branch"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please enter branch name, description and code for branch Addition.
          </p>
          <Formik
            initialValues={{
              branch_name: selectedBranch?.branchName || "",
              branch_description: selectedBranch?.branchDescription || "",
              branch_code: selectedBranch?.branchCode || "",
              workflow_name: selectedBranch?.name || "",
              workflows: selectedBranch?.workflowData || [
                {
                  workflow: "reviewer",
                  users: [],
                  no_of_approvals: "",
                },
                {
                  workflow: "approver",
                  users: [],
                  no_of_approvals: "",
                },
              ],
              disabled:
                selectedBranch?.disabled === false ||
                selectedBranch?.disabled === "No"
                  ? true
                  : false,
              email: user?.email || "",
              password: "",
            }}
            validationSchema={
              setting.digital_signature ? updateBranchSchema : branchSchema
            }
            onSubmit={(values) =>
              selectedBranch ? updateBranch(values) : addBranch(values)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" for="contact_number">
                    Branch Name
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Branch Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.branch_name}
                    name="branch_name"
                    id="branch_name"
                  />
                  <CustomErrorMessage
                    name="branch_name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Branch Description
                    <span className="required-star"> *</span>
                  </label>
                  <textarea
                    className="form-control employee-custom-form-control"
                    placeholder="Enter Branch Description"
                    aria-label="With textarea"
                    name="branch_description"
                    id="branch_description"
                    value={values.branch_description}
                    onChange={handleChange}
                  ></textarea>
                  <CustomErrorMessage
                    name="branch_description"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Branch Code
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Branch Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.branch_code}
                    name="branch_code"
                    id="branch_code"
                  />
                  <CustomErrorMessage
                    name="branch_code"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                {isWorkflowFlag &&
                  isWorkflowFlag?.standard_workflow === "false" &&
                  isWorkflowFlag?.workflow_disabled === "No" && (
                    <>
                      <div
                        className="my-4"
                        style={{ borderBottom: "1px dashed #000" }}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0" style={{ fontWeight: 500 }}>
                          Manage Workflow :
                        </p>
                        <Button
                          className="theme-btn-main"
                          style={{ padding: "11px 12px" }}
                          onClick={() =>
                            onManageWorkflow({
                              values,
                              setFieldValue,
                            })
                          }
                        >
                          Manage Workflow
                        </Button>
                      </div>
                      <div className="workflows-list">
                        {values.workflows &&
                          values.workflows.length > 0 &&
                          values.workflows.map((each_work, index) => {
                            // Generate the options array for the number of approvals
                            const options = Array.from(
                              {
                                length:
                                  each_work?.users &&
                                  each_work?.users.length > 0 &&
                                  each_work?.users.length,
                              },
                              (_, i) => ({
                                label: `${i + 1}`,
                                value: i + 1,
                              })
                            );

                            return (
                              <div className="each-work-flow mt-3" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="workflow-head">
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {each_work.workflow}
                                    </span>
                                  </div>
                                  <div className="form-group mb-1 d-flex gap-2 align-items-center">
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="category"
                                    >
                                      {`Select Number of ${each_work.workflow}`}
                                      <span style={{ color: "#f00" }}> *</span>
                                    </label>
                                    <div className="form-control-wrap">
                                      <select
                                        className="sop-custom-form-select"
                                        id={`workflows.${index}.no_of_approvals`}
                                        name={`workflows.${index}.no_of_approvals`}
                                        placeholder="Select"
                                        onChange={(option) => {
                                          setFieldValue(
                                            `workflows.${index}.no_of_approvals`,
                                            option?.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        style={{
                                          width: "100px",
                                          fontSize: "12px",
                                          padding: "6px",
                                        }}
                                      >
                                        <option>Select...</option>
                                        {options &&
                                          options?.length > 0 &&
                                          options.map((option) => {
                                            return (
                                              <option value={option.value}>
                                                {option.label}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <CustomErrorMessage
                                        name={`noOfApprovals_${index}`}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="users mt-2 px-3">
                                  {each_work.users &&
                                    each_work.users.length > 0 &&
                                    each_work.users.map((user, subI) => (
                                      <div
                                        className="each-user d-flex justify-content-between mt-2"
                                        key={subI}
                                      >
                                        <div>
                                          <span>{subI + 1}.</span>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {user.label}
                                          </span>
                                        </div>
                                        <div>
                                          <img
                                            alt="Cross"
                                            src={Cross}
                                            className="delete-topic"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              let data =
                                                values?.workflows || [];
                                              let users =
                                                data[index].users || [];
                                              users = users.filter(
                                                (each, i) => subI !== i
                                              );
                                              data[index].users = users;
                                              setFieldValue("workflows", data);
                                              setFieldValue(
                                                `workflows.${index}.no_of_approvals`,
                                                ""
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* {values.workflows &&
                                values.workflows.length > 0 &&
                                values.workflows[0]?.users &&
                                values.workflows[0]?.users.length === 0 && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      backgroundColor: "white",
                                      padding: "5px",
                                    }}
                                  >
                                    Will Appear Here
                                  </div>
                                )} */}
                    </>
                  )}

                {setting.digital_signature && (
                  <>
                    <div
                      className="my-4"
                      style={{ borderBottom: "1px dashed #000" }}
                    />
                    <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                      Digital Signature :
                    </p>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Login Id</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Enter Login Id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                          type="text"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Password</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          type="password"
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="password"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button
                      className="back-icon-btn-main"
                      onClick={onAddBranch}
                    >
                      <span>{squareBackIcon}</span>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="theme-btn-main" type="submit">
                      {buttonLoading ? (
                        <ButtonLoading />
                      ) : selectedBranch ? (
                        "Update changes"
                      ) : (
                        "Submit changes"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={deleteBranchModal}
        onHide={onDeleteBranch}
        className="add-department-modal-main"
        selected={selectedBranch}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Branch
          </Modal.Title>
        </Modal.Header>

        {deleteBranchModal && (
          <Modal.Body className="add-department-modal-body">
            <p>
              Do you want to delete this Branch{" "}
              <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
                {selectedBranch.branchName}
              </span>
              ?
            </p>

            <Formik
              initialValues={{ email: user?.email, password: "" }}
              validationSchema={setting.digital_signature && deleteSchema}
              onSubmit={(values) =>
                onBranchStatusChange({
                  status: "inactive",
                  password: values?.password,
                })
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <Form className="form" onSubmit={handleSubmit}>
                  {setting.digital_signature && (
                    <>
                      <div
                        className="my-4"
                        style={{ borderBottom: "1px dashed #000" }}
                      />
                      <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                        Digital Signature :
                      </p>

                      <div className="form-group mt-3 col-12 ">
                        <label className="mx-3 mb-1">Login Id</label>

                        <div className="w-100 px-3">
                          <PrimaryInput
                            placeholder="Enter Login Id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            name="email"
                            type="text"
                            disabled={true}
                          />
                          <CustomErrorMessage
                            errors={errors}
                            touched={touched}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="form-group mt-3 col-12 ">
                        <label className="mx-3 mb-1">Password</label>

                        <div className="w-100 px-3">
                          <PrimaryInput
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                            type="password"
                          />
                          <CustomErrorMessage
                            errors={errors}
                            touched={touched}
                            name="password"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-3">
                    <div className="d-flex gap-2">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onDeleteBranch}
                      >
                        <span>{squareBackIcon}</span>
                        <span className="ms-2">Back</span>
                      </Button>
                      <Button className="theme-btn-main" type="submit">
                        {buttonLoading ? <ButtonLoading /> : "Delete"}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        )}
      </Modal>

      {/* <Modal
        centered
        show={statusModal}
        onHide={onStatusModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Status
          </Modal.Title>
        </Modal.Header>

        {statusModal && (
          <Modal.Body className="add-department-modal-body">
            <StatusModalContent
              setting={setting}
              statusApiFunction={onBranchStatusChange}
              onClose={onStatusModal}
              loading={buttonLoading}
            />
          </Modal.Body>
        )}
      </Modal> */}

      <Modal
        centered
        show={workflowModal}
        onHide={() => onManageWorkflow({})}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Manage Workflow
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <IsWorkflowSlug
            onManageWorkflow={() => onManageWorkflow({})}
            workflowDataSet={workflowDataSet}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BranchMaster;
