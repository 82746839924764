import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import moment from "moment";
import PageLoader from "../../../assets/loaders/PageLoading";
import ApprovalStatusModal from "../../../assets/modal/ApprovalStatusModal";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import { Button, Modal } from "react-bootstrap";
import { Table } from "reactstrap";
import "./PlantDetails.scss";
import Back_Arrow from "../../../assets/images/icons/arrow-left.svg";
import editicon from "../../../assets/images/icons/edit.svg";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import { Formik } from "formik";
import useAuth from "../../../hooks/useAuth";
import useFeatureSetting from "../../../hooks/useFeatureSetting";
import { InputGroup, Form } from "react-bootstrap";
import { PrimaryInput } from "../../../assets/FormElements";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import Select from "react-select";
import * as Yup from "yup";
import Cross from "../../../assets/images/icons/cross.svg";
import IsWorkflowSlug from "../../../assets/modal/IsWorkflowSlug";

const PlantDetails = () => {
  const branchSchema = Yup.object().shape({
    plant_name: Yup.string().transform((value) => value.trim()).required("Plant Name is Required"),
    plant_code: Yup.string().transform((value) => value.trim()).required("Plant Code is Required"),
    plant_address: Yup.string().transform((value) => value.trim()).required("Plant Address is Required"),
  });

  const updateBranchSchema = Yup.object().shape({
    plant_name: Yup.string().transform((value) => value.trim()).required("Plant Name is Required"),
    plant_code: Yup.string().transform((value) => value.trim()).required("Plant Code is Required"),
    plant_address: Yup.string().transform((value) => value.trim()).required("Plant Address is Required"),
    password: Yup.string().transform((value) => value.trim()).required("Password is Required"),
  });

  const { updateCurrentPage } = useCurrentPage();
  const {
    getPlantById,
    updateEventWorkflowReview,
    getEventWorkflowById,
    getWorkFlowsByType,
    getWorkFlowHistoryByType,
    updatePlant,
    getBranchMasters,
    getWorkflowsBySlug,
  } = MainServices();
  const [pageLoading, setPageLoading] = useState(0);
  const [plantMasters, setPlantMasters] = useState([]);
  const [workflowMasters, setWorkflowMasters] = useState({});
  const location = useLocation();
  const [approvedLoading, setApprovedLoading] = useState(0);
  const [approvedModal, setApprovedModal] = useState(false);
  const [sopWorkFlows, setSopWorkFlows] = useState([]);
  const [workFlowHisories, setWorkFlowHisories] = useState([]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(0);
  const [selectedPlant, setSelectedPlant] = useState({});
  const [addPlantModal, setAddPlantModal] = useState(false);
  const { user } = useAuth();
  const [branchChange, setBranchChange] = useState(false);
  const [intiLoading, setIntiLoading] = useState(0);
  //url Params
  const publicId = new URLSearchParams(location.search).get("publicId");
  const refId = new URLSearchParams(location.search).get("refId");
  const workflowConcurrencystamp = new URLSearchParams(location.search).get(
    "workflowConcurrencystamp"
  );
  const [branches, setBranches] = useState([]);
  const { grantPermission } = PermissionFunctions();
  const { updateSettingContext, setting } = useFeatureSetting();
  const edit = new URLSearchParams(location.search).get("edit");
  const [editInitiated, setEditInitiated] = useState(false);
  const [workflowAssign, setWorkflowAssign] = useState({});
  const [isWorkflowFlag, setIsWorkflowFlag] = useState({});
  const [workflowDataSet, setWorkflowDataSet] = useState("");
  const [workflowModal, setWorkflowModal] = useState(false);

  useEffect(() => {
    updateCurrentPage({
      title: "Plant",
      subtitle: "Details",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSettingContext("plant");
  }, []);

  useEffect(() => {
    if (publicId) {
      getPlantMasterByStatus();
      getSopWorkflows();
      getWorkflowHistory();
      getIsWorkflowSlug();
    }
    // eslint-disable-next-line
  }, [publicId]);

  useEffect(() => {
    if (refId) {
      getWorkflowById();
    }
    // eslint-disable-next-line
  }, [refId]);

  const getPlantMasterByStatus = async () => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getPlantById(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let plants = resData.data ? resData.data : [];
            setPlantMasters(plants);
            if (edit === "true" && !editInitiated) {
              onAddPlant(plants);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Plants");
      });
  };

  const getWorkflowById = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: refId }],
    };

    await axios
      .all([getEventWorkflowById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : [];
            setWorkflowMasters(workflows[0]);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Event Workflow");
      });
  };

  const updateWorkflowStatus = async (values) => {
    setApprovedLoading(true);
    const details = {
      workflowStatus: values.status?.value,
      //   email: values.user_id,
      ...(values?.password && { password: values.password }),
      comments: values.comment,
    };
    await axios
      .all([
        updateEventWorkflowReview(details, refId, workflowConcurrencystamp),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("Status changed successfully");
            setApprovedLoading(false);
            onApprovedStatusModal();
            getPlantMasterByStatus();
            getWorkflowById();
            getSopWorkflows();
            getWorkflowHistory();
          } else {
            message && notifyWarn(message);
            setApprovedLoading(false);
          }
        } else {
          setApprovedLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in status update");
      });
  };

  const getSopWorkflows = async () => {
    const details = {
      workFlowType: "plant",
      sorting: [{ key: "id", direction: "asc" }],
    };
    await axios
      .all([getWorkFlowsByType(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : {};
            setWorkflowAssign(workflows);
            workflows = workflows.eventWorkflows
              ? workflows.eventWorkflows
              : [];
            setSopWorkFlows(workflows);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  const getWorkflowHistory = async () => {
    const details = {
      sorting: [{ key: "id", direction: "desc" }],
    };
    await axios
      .all([getWorkFlowHistoryByType(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflowHistory = resData.data ? resData.data : [];
            setWorkFlowHisories(workflowHistory);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  const updatePlantMaster = async (values) => {
    setButtonLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      plantName: values.plant_name,
      address: values.plant_address,
      plantCode: values.plant_code,
      ...(values?.password && { password: values?.password }),
      ...(branchChange && { branchId: values?.branchId?.publicId }),
    };
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([
        updatePlant(
          details,
          selectedPlant.publicId,
          selectedPlant.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getPlantMasterByStatus();
            onAddPlant();
            notifySuccess("Plant Master Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Plant Master");
      });
  };

  const updateBranchIntiated = async (values) => {
    setIntiLoading(true);
    const details = {
      plantName: values.plant_name,
      address: values.plant_address,
      plantCode: values.plant_code,
      ...(values?.password && { password: values?.password }),
      ...(branchChange && { branchId: values?.branchId?.publicId }),
      ...(values?.password && { password: values?.password }),
      reinitiateWorkflowAssignId: workflowAssign.publicId,
      status: "reinitiated",
    };
    await axios
      .all([
        updatePlant(
          details,
          selectedPlant.publicId,
          selectedPlant.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getSopWorkflows();
            onAddPlant();
            getPlantMasterByStatus({}, publicId);
            notifySuccess("Branch Master Updated Successfully");
            setIntiLoading(false);
          } else {
            message && notifyWarn(message);
            setIntiLoading(false);
          }
        } else {
          setIntiLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Branch Master");
      });
  };

  const getBranches = async (filters) => {
    setPageLoading(true);
    const details = {
      filters,
      status: "active",
    };
    let results = await getBranchMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let branches = resData.data ? resData.data : [];
            branches = branches.map((_) => {
              return {
                ..._,
                label: _?.branchName,
                value: _?.publicId,
              };
            });
            branches.unshift({ label: "--All--", value: "" });
            setBranches(branches);
            setPageLoading(false);
            return branches;
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting branches");
      });
    return results;
  };

  const getIsWorkflowSlug = async () => {
    const details = {};
    await axios
      .all([getWorkflowsBySlug(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let isWorkflow = resData?.data ? resData?.data : {};
            isWorkflow = isWorkflow?.content
              ? JSON.parse(isWorkflow?.content)
              : {};
            setIsWorkflowFlag(isWorkflow);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting measurement types");
      });
  };

  //functions
  const onManageWorkflow = ({ values, setFieldValue }) => {
    setWorkflowDataSet({
      workflows: values?.workflows,
      setFieldValue,
    });
    setWorkflowModal((prev) => !prev);
  };

  const onApprovedStatusModal = () => {
    setApprovedModal(!approvedModal);
  };
  const onAddPlant = (selected) => {
    if (selected) {
      setSelectedPlant(selected);
    } else {
      setSelectedPlant("");
    }
    setAddPlantModal(!addPlantModal);
  };

  return (
    <div className="plant-details-main">
      <div className="admin-popular-main">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="header">
            <p className="content-header-main mb-0">Plant Details</p>
          </div>
          {refId && workflowMasters?.workflowStatus === "pending" && (
            <Button onClick={onApprovedStatusModal}>Update Status</Button>
          )}
        </div>
        <div className="mb-3">
          <img
            src={Back_Arrow}
            alt="backArrow"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {pageLoading ? (
          <PageLoader />
        ) : plantMasters ? (
          <div className="content">
            <div className="table-main">
              {grantPermission("Plant_update") &&
                sopWorkFlows?.status !== "inactive" && (
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex justify-content-end mb-3"
                  >
                    <img
                      src={editicon}
                      alt="Edit icon"
                      onClick={() => onAddPlant(plantMasters)}
                    />
                  </div>
                )}
              <div className="row details-row">
                <div className="col-md-6 col-head fw-bold">Name</div>
                <div className="col-md-6 ">{plantMasters?.plantName}</div>

                <div className="col-md-6 col-head fw-bold">Code</div>
                <div className="col-md-6 ">{plantMasters?.plantCode}</div>

                <div className="col-md-6 col-head fw-bold">Address</div>
                <div className="col-md-6 ">{plantMasters?.address}</div>

                <div className="col-md-6 col-head fw-bold">Branch</div>
                <div className="col-md-6 ">
                  {plantMasters?.branch?.branchName}
                </div>

                <div className="col-md-6 col-head fw-bold">Created At</div>
                <div className="col-md-6 ">
                  {moment(plantMasters?.createdAt).format("DD-MM-YYYY")}
                </div>

                <div className="col-md-6 col-head fw-bold">Status</div>
                <div className="col-md-6 ">{plantMasters?.status}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Plant Found</div>
        )}

        {sopWorkFlows && sopWorkFlows.length > 0 ? (
          <div className="mt-5 doc-properties-details-main">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="doc-properties-header-main fw-bold">
                  Workflow
                </span>
              </div>
            </div>

            <div className="workflows content">
              <div className="table-main mt-4">
                <Table responsive bordered className="table table-bordered">
                  <thead className="service-table-head table-header-color">
                    <tr>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Name
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Status
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Initiated On
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Approved On
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sopWorkFlows.map((each, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.user?.empName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowStatus}
                        </td>
                        <td>
                          {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                        </td>
                        <td>
                          {moment(each?.updatedAt).format("DD MMM YYYY HH:mm")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {workFlowHisories && workFlowHisories.length > 0 ? (
          <div className="mt-5 doc-properties-details-main">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="doc-properties-header-main fw-bold">
                  Workflow History
                </span>
              </div>
            </div>

            <div className="workflows content">
              <div className="table-main mt-4">
                <Table responsive bordered className="table table-bordered">
                  <thead className="service-table-head table-header-color">
                    <tr>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Date & Time
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Name
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Status
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workFlowHisories.map((each, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.user?.empName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowStatus}
                        </td>
                        <td>{each?.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        centered
        show={addPlantModal}
        onHide={onAddPlant}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Add New Plant
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please enter plant name, address and code for plant Addition.
          </p>
          <Formik
            initialValues={{
              plant_name: selectedPlant?.plantName || "",
              plant_address: selectedPlant?.address || "",
              plant_code: selectedPlant?.plantCode || "",
              branchId: selectedPlant?.branch?.publicId
                ? {
                    label: selectedPlant.branch.branchName,
                    value: selectedPlant.branch.publicId,
                  }
                : "",
              workflow_name: selectedPlant?.name || "",
              workflows: selectedPlant?.workflowData || [
                {
                  workflow: "reviewer",
                  users: [],
                  no_of_approvals: "",
                },
                {
                  workflow: "approver",
                  users: [],
                  no_of_approvals: "",
                },
              ],
              disabled:
                selectedPlant?.disabled === false ||
                selectedPlant?.disabled === "No"
                  ? true
                  : false,
              email: user?.email || "",
              password: "",
            }}
            validationSchema={
              setting.digital_signature ? updateBranchSchema : branchSchema
            }
            onSubmit={(values) => updatePlantMaster(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <div>
                    <label className="form-label" for="branchId">
                      Branch
                      <span className="required-star"> *</span>
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        className="sop-custom-form-select"
                        id="branchId"
                        name="branchId"
                        placeholder="Select..."
                        onChange={(e) => {
                          setBranchChange(true);
                          setFieldValue("branchId", e);
                        }}
                        onBlur={handleBlur}
                        value={values.branchId}
                        options={branches}
                      />
                      <CustomErrorMessage
                        name="branchId"
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" for="contact_number">
                    Plant Name
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Plant Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.plant_name}
                    name="plant_name"
                    id="plant_name"
                  />
                  <CustomErrorMessage
                    name="plant_name"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Plant Code
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Plant Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.plant_code}
                    name="plant_code"
                    id="plant_code"
                  />
                  <CustomErrorMessage
                    name="plant_code"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Plant Address
                    <span className="required-star"> *</span>
                  </label>
                  <textarea
                    className="form-control employee-custom-form-control"
                    placeholder="Enter Plant Address"
                    aria-label="With textarea"
                    name="plant_address"
                    id="plant_address"
                    value={values.plant_address}
                    onChange={handleChange}
                  ></textarea>
                  <CustomErrorMessage
                    name="plant_address"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                {isWorkflowFlag &&
                  isWorkflowFlag?.standard_workflow === "false" &&
                  isWorkflowFlag?.workflow_disabled === "No" &&
                  selectedPlant?.status === "approved" && (
                    <>
                      <div
                        className="my-4"
                        style={{ borderBottom: "1px dashed #000" }}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0" style={{ fontWeight: 500 }}>
                          Manage Workflow :
                        </p>
                        <Button
                          className="theme-btn-main"
                          style={{ padding: "11px 12px" }}
                          onClick={() =>
                            onManageWorkflow({
                              values,
                              setFieldValue,
                            })
                          }
                        >
                          Manage Workflow
                        </Button>
                      </div>
                      <div className="workflows-list">
                        {values.workflows &&
                          values.workflows.length > 0 &&
                          values.workflows.map((each_work, index) => {
                            // Generate the options array for the number of approvals
                            const options = Array.from(
                              {
                                length:
                                  each_work?.users &&
                                  each_work?.users.length > 0 &&
                                  each_work?.users.length,
                              },
                              (_, i) => ({
                                label: `${i + 1}`,
                                value: i + 1,
                              })
                            );

                            return (
                              <div className="each-work-flow mt-3" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="workflow-head">
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {each_work.workflow}
                                    </span>
                                  </div>
                                  <div className="form-group mb-1 d-flex gap-2 align-items-center">
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="category"
                                    >
                                      {`Select Number of ${each_work.workflow}`}
                                      <span style={{ color: "#f00" }}> *</span>
                                    </label>
                                    <div className="form-control-wrap">
                                      <select
                                        className="sop-custom-form-select"
                                        id={`workflows.${index}.no_of_approvals`}
                                        name={`workflows.${index}.no_of_approvals`}
                                        placeholder="Select"
                                        onChange={(option) => {
                                          setFieldValue(
                                            `workflows.${index}.no_of_approvals`,
                                            option?.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        style={{
                                          width: "100px",
                                          fontSize: "12px",
                                          padding: "6px",
                                        }}
                                      >
                                        <option>Select...</option>
                                        {options &&
                                          options?.length > 0 &&
                                          options.map((option) => {
                                            return (
                                              <option value={option.value}>
                                                {option.label}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <CustomErrorMessage
                                        name={`noOfApprovals_${index}`}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="users mt-2 px-3">
                                  {each_work.users &&
                                    each_work.users.length > 0 &&
                                    each_work.users.map((user, subI) => (
                                      <div
                                        className="each-user d-flex justify-content-between mt-2"
                                        key={subI}
                                      >
                                        <div>
                                          <span>{subI + 1}.</span>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {user.label}
                                          </span>
                                        </div>
                                        <div>
                                          <img
                                            alt="Cross"
                                            src={Cross}
                                            className="delete-topic"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              let data =
                                                values?.workflows || [];
                                              let users =
                                                data[index].users || [];
                                              users = users.filter(
                                                (each, i) => subI !== i
                                              );
                                              data[index].users = users;
                                              setFieldValue("workflows", data);
                                              setFieldValue(
                                                `workflows.${index}.no_of_approvals`,
                                                ""
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* {values.workflows &&
                                values.workflows.length > 0 &&
                                values.workflows[0]?.users &&
                                values.workflows[0]?.users.length === 0 && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      backgroundColor: "white",
                                      padding: "5px",
                                    }}
                                  >
                                    Will Appear Here
                                  </div>
                                )} */}
                    </>
                  )}

                {setting.digital_signature && selectedPlant && (
                  <>
                    <div
                      className="my-4"
                      style={{ borderBottom: "1px dashed #000" }}
                    />
                    <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                      Digital Signature :
                    </p>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Login Id</label>
                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Enter Login Id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                          type="text"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Password</label>
                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          type="password"
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="password"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button className="back-icon-btn-main" onClick={onAddPlant}>
                      <span>{squareBackIcon}</span>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="theme-btn-main" type="submit">
                      {buttonLoading ? (
                        <ButtonLoading />
                      ) : selectedPlant ? (
                        "Update"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    {selectedPlant?.status === "rejected" && (
                      <Button
                        className="theme-btn-main"
                        onClick={() => {
                          updateBranchIntiated(values);
                        }}
                      >
                        {intiLoading ? (
                          <ButtonLoading />
                        ) : selectedPlant ? (
                          "Update & Reinitiate"
                        ) : (
                          ""
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={approvedModal}
        onHide={onApprovedStatusModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Status
          </Modal.Title>
        </Modal.Header>

        {approvedModal && (
          <Modal.Body className="add-department-modal-body">
            <ApprovalStatusModal
              statusApiFunction={updateWorkflowStatus}
              onClose={onApprovedStatusModal}
              loading={approvedLoading}
            />
          </Modal.Body>
        )}
      </Modal>
      <Modal
        centered
        show={workflowModal}
        onHide={() => onManageWorkflow({})}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Manage Workflow
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <IsWorkflowSlug
            onManageWorkflow={() => onManageWorkflow({})}
            workflowDataSet={workflowDataSet}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PlantDetails;
