import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "reactstrap";
import axios from "axios";
import MainServices from "../../services/MainServices";

export default function WorkflowHistory(props) {
  const [workFlowHistories, setWorkFlowHisories] = useState([]);
  const { getWorkFlowHistoryByType } = MainServices();

  useEffect(() => {
    getWorkflowHistory();
    // eslint-disable-next-line
  }, [props?.publicId, props?.isStatusUpdate]);

  //apis
  const getWorkflowHistory = async () => {
    const details = {
      sorting: [{ key: "id", direction: "desc" }],
    };
    await axios
      .all([getWorkFlowHistoryByType(details, props?.publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflowHistory = resData.data ? resData.data : [];
            setWorkFlowHisories(workflowHistory);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  return (
    <div>
      {workFlowHistories && workFlowHistories.length > 0 ? (
        <div className="mt-5 doc-properties-details-main">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="doc-properties-header-main fw-bold">
                Workflow History
              </span>
            </div>
          </div>

          <div className="workflows content">
            <div className="table-main mt-4">
              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head table-header-color">
                  <tr>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Date & Time</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>
                      Workflow Name
                    </th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>
                      Workflow Status
                    </th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {workFlowHistories.map((each, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {each?.workflowName}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {each?.user?.empName}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {each?.workflowStatus}
                      </td>
                      <td>{each?.comments}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
