import FetchServices from "./FetchServices";
import apiService from "./ApiServices";
import useConfig from "../hooks/useConfig";
import axios from "axios";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const config = useConfig();

  const Services = {
    //files Functions
    fileUpload: function (file) {
      const api = config.api;
      let API = api + apiService.uploadFile;
      var bodyFormData = new FormData();
      let fieldNames = [];
      file &&
        Object.keys(file).map((key) => {
          fieldNames.push(file[key].field);
          bodyFormData.append("files", file[key]);
          return true;
        });
      if (fieldNames && fieldNames.length > 0) {
        bodyFormData.append("fileLabels", JSON.stringify(fieldNames));
      }
      return axios({
        method: "POST",
        url: API,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        data: bodyFormData,
      })
        .then(function (response) {
          var resData = response.data;
          if (!resData) {
            const message = resData.msg;
            const errorRes = { status: false, msg: message };
            return errorRes;
          }
          const apiResponse = resData;
          return { status: true, data: apiResponse, success: true };
        })
        .catch(function (res) {
          console.log(res);
          console.log("An error occurred in File Upload");
        });
    },

    //User functions
    userSignin: function (details) {
      let API = config.api + apiService.userSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: false, type }).then(
        (res) => {
          return res;
        }
      );
    },
    userGoogleSignin: function () {
      let API = config.api + apiService.userGoogleSignin;
      window.open(API, "_self");
    },
    userFacebookSignin: function () {
      let API = config.api + apiService.userFacebookSignin;
      window.open(API, "_self");
    },
    getUserDetails: function (details, token) {
      let API = config.api + apiService.getUserDetails;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },

    resetpassword: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.resetpassword + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //permission api
    getRolePermissionsByRoles: function (details, token) {
      let API = config.api + apiService.getRolePermissionsByRoles;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    getAllPermission: function (details, token) {
      let API = config.api + apiService.getAllPermission;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },

    //Branch Start
    getBranchMasters: function (details) {
      let API = config.api + apiService.getBranchMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addBranchMaster: function (details) {
      let API = config.api + apiService.addBranchMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateBranchMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateBranchMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteBranchMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteBranchMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Document Category Start
    getAuditTrails: function (details) {
      let API = config.api + apiService.getAuditTrails;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Department Start
    getDepartmentMasters: function (details) {
      let API = config.api + apiService.getDepartmentMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addDepartmentMaster: function (details) {
      let API = config.api + apiService.addDepartmentMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDepartmentMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateDepartmentMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDepartmentMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteDepartmentMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Designation start
    getDesignationMasters: function (details) {
      let API = config.api + apiService.getDesignationMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addDesignationMaster: function (details) {
      let API = config.api + apiService.addDesignationMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDesignationMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.updateDesignationMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDesignationMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.deleteDesignationMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Employee MAster
    getEmployeeMasters: function (details) {
      let API = config.api + apiService.getEmployeeMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addEmployeeMaster: function (details) {
      let API = config.api + apiService.addEmployeeMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateEmployeeMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateEmployeeMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteEmployeeMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteEmployeeMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    employeeStatusUpdateMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.employeeStatusUpdateMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getEmpProfile: function (details, publicId) {
      let API = config.api + apiService.getEmpProfile + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    //Access Right Start
    getAccessRightMasters: function (details) {
      let API = config.api + apiService.getAccessRightMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addAccessRightMaster: function (details) {
      let API = config.api + apiService.addAccessRightMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateAccessRightMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.updateAccessRightMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteAccessRightMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.deleteAccessRightMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //operation api
    getOpertationsByStatus: function (details) {
      let API = config.api + apiService.getOpertationsByStatus;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Access role apis
    getRolePermissionMasters: function (details) {
      let API = config.api + apiService.getRolePermissionMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    getRoleMasters: function (details) {
      let API = config.api + apiService.getRoleMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getSecurityGroupPermissionMaster: function (details) {
      let API = config.api + apiService.getSecurityGroupPermissionMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addSecurityGroupPermissionMaster: function (details) {
      let API = config.api + apiService.addSecurityGroupPermissionMaster;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateSecurityGroupPermissionMaster: function (details) {
      let API = config.api + apiService.updateSecurityGroupPermissionMaster;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    deleteSecurityGroupPermissionMaster: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.api +
        apiService.deleteSecurityGroupPermissionMaster +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getSecurityGroupMaster: function (details) {
      let API = config.api + apiService.getSecurityGroupMaster;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addSecurityGroupMaster: function (details) {
      let API = config.api + apiService.addSecurityGroupMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateSecurityGroupMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.updateSecurityGroupMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteSecurityGroupMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.deleteSecurityGroupMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    changeSecurityGroupStatusMaster: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.api + apiService.deleteSecurityGroupMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    addRolePermissionMaster: function (details) {
      let API = config.api + apiService.addRolePermissionMaster;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateRolePermissionMaster: function (details) {
      let API = config.api + apiService.updateRolePermissionMaster;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    deleteRolePermissionMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.deleteRolePermissionMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    // tools
    getToolsAccess: function (details, token) {
      let API = config.api + apiService.getToolsAccess;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    getSettings: function (details) {
      let API = config.api + apiService.getSettings;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addSetting: function (details) {
      let API = config.api + apiService.featureSetting;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateSetting: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.featureSetting + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    updateSettingStatus: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateSettingStatus + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getSettingBySlug: function (publicId) {
      let API = config.api + apiService.getSettingBySlug + "/" + publicId;
      let payload = {};
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Doctype api start
    getDoctypeMasters: function (details) {
      let API = config.api + apiService.doctypeMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addDoctypeMaster: function (details) {
      let API = config.api + apiService.doctypeMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDoctypeMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.doctypeMasters + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDoctypeMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteDoctypeMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Doctype api start
    getPlants: function (details) {
      let API = config.api + apiService.getPlants;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addPlant: function (details) {
      let API = config.api + apiService.addPlant;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updatePlant: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updatePlant + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deletePlant: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deletePlant + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    changePlantStatusMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deletePlant + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //division apis
    addDivisionMaster: function (details) {
      let API = config.api + apiService.addDivisionMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    getDivisionMaster: function (details) {
      let API = config.api + apiService.getDivisionMaster;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDivision: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateDivision + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDivisionMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteDivisionMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    changeDivisionStatusMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteDivisionMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //toolsInformationapis
    getToolsInformation: function (details, token) {
      let API = config.api + apiService.getToolsInformation;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    //passwordPolicyapis
    getUserSetting: function (details, slug) {
      let API = config.api + apiService.getUserSetting + "/" + slug;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updatePolicy: function (details, publicId, concurrencyStamp) {
      if (!publicId) {
        return;
      }
      let API = config.api + apiService.updatePolicy + "/" + publicId;
      let payload = {
        ...details,
      };
      console.log(payload, "payload");
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    // IDC Matrix
    getIDCMasters: function (details) {
      let API = config.api + apiService.getIDCMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    AddIDCMasters: function (details) {
      let API = config.api + apiService.AddIDCMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateIDCMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateIDCMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteIDCMaster: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteIDCMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //workflowgroup
    getWorkflowGroup: function (details) {
      let API = config.api + apiService.getWorkflowGroup;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addWorkflowGroup: function (details) {
      let API = config.api + apiService.addWorkflowGroup;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getWorkflowGroupById: function (details, publicId) {
      let API = config.api + apiService.getWorkflowGroupById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    updateWorkflowGroup: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateWorkflowGroup + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteWorkflowGroup: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.deleteWorkflowGroup + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //Dashboard Workflow apis
    getEventWorkflowReview: function (details, token) {
      let API = config.api + apiService.getEventWorkflowReview;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    getWorkfowsPaginationCount: function (details, token) {
      let API = config.api + apiService.getWorkfowsPaginationCount;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },

    updateEventWorkflowReview: function (details, publicId, concurrencyStamp) {
      let API =
        config.api + apiService.updateEventWorkflowReview + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    getEventWorkflowById: function (details, token) {
      let API = config.api + apiService.getEventWorkflowById;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },

    getWorkFlowsByType: function (details, id) {
      let API = config.api + apiService.getWorkFlowsByType + "/" + id;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    getWorkFlowHistoryByType: function (details, id) {
      let API = config.api + apiService.getWorkFlowHistoryByType + "/" + id;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    getBranchById: function (details, publicId) {
      let API = config.api + apiService.getBranchById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getPlantById: function (details, publicId) {
      let API = config.api + apiService.getPlantById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getDivisionById: function (details, publicId) {
      let API = config.api + apiService.getDivisionById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    getByDepartmentById: function (details, publicId) {
      let API = config.api + apiService.getByDepartmentById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getDesignationById: function (details, publicId) {
      let API = config.api + apiService.getDesignationById + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    //workflow slug
    getWorkflowsBySlug: function (details) {
      let API =
        config.api +
        apiService.getWorkflowsBySlug +
        "/" +
        "standard-workflow";
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
  };

  return Services;
}
