import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import useCurrentPage from "../../../hooks/useCurrentPage";
import PageLoader from "../../../assets/loaders/PageLoading";
import ArrowBack from "../../../assets/images/icons/arrow-left.svg";
import { Label } from "reactstrap";
import "./IDCMatrixDetails.scss";
import { Table } from "reactstrap";
import editIcon from "../../../assets/images/icons/edit.svg";

export default function IDCMatrixDetails() {
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [pageLoading, setPageLoading] = useState(false);
  const [IDCMatrix, setIDCMatrix] = useState();
  const { updateCurrentPage } = useCurrentPage();
  const { getIDCMasters } = MainServices();
  const navigate = useNavigate();

  useEffect(() => {
    updateCurrentPage({
      title: "WorkFlow ",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIDCMatrix();
  }, [publicId]);

  const getIDCMatrix = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: publicId }],
    };
    await axios
      .all([getIDCMasters(details)])
      .then((res) => {
        let resData = res[0];
        if (resData.success) {
          console.log("Data", resData.data[0]);
          setIDCMatrix(resData.data[0]);
          setPageLoading(false);
        } else {
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  return (
    <div className="idcmatrix-details-main">
      <div className="d-flex justify-content-between">
        <div className="back-btn-function">
          <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="mt-3">
          {IDCMatrix ? (
            <div className="details-workflow-main">
              <div className="pre-heading">
                <div class="row align-items-center">
                  <div class="col">
                    <h1 class="add-project-card-header-main mb-1">
                      WorkFlow Details
                    </h1>
                  </div>
                  <hr />
                  <div className="project-details-page-main">
                    <div className="product-details-data-main mt-3">
                      {IDCMatrix && (
                        <>
                          <div
                            className="profile-certificate-edit-main d-flex justify-content-end"
                            onClick={() => {
                              navigate({
                                pathname: "/workflow/add-workflow",
                                search: `?publicId=${IDCMatrix?.publicId}`,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="edit-icon-main">
                              <img src={editIcon} alt="edit icon" />
                            </span>
                            <span>Edit</span>
                          </div>

                          <div className="row align-items-center mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label style={{ fontSize: "20px" }}>
                                WorkFlow Name:
                              </Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <p style={{ fontSize: "20px" }}>
                                {IDCMatrix.workflowName}
                              </p>
                            </div>
                          </div>

                          <div className="row align-items-center  mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label style={{ fontSize: "20px" }}>Matrix</Label>
                            </div>
                          </div>
                          {IDCMatrix?.workflowData &&
                            IDCMatrix?.workflowData.length > 0 &&
                            IDCMatrix?.workflowData.map((data, index) => {
                              return (
                                <>
                                  <p
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: "18px",
                                    }}
                                    className="mb-4"
                                  >
                                    <b>{data?.workflow}</b>
                                  </p>
                                  {data?.users && data?.users.length > 0 && (
                                    <Table
                                      responsive
                                      bordered
                                      className="table table-bordered"
                                    >
                                      <thead className="service-table-head">
                                        <tr>
                                          <th>S.No.</th>
                                          <th> Name</th>
                                          <th>Designation</th>
                                          <th>Discipline</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.users.map((each) => {
                                          return (
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{each.label}</td>
                                              <td>
                                                {each?.designation?.desgName}
                                              </td>
                                              <td>
                                                {each?.deptartment?.deptName}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  )}
                                </>
                              );
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>No WorkFlow Found</div>
          )}
        </div>
      )}
    </div>
  );
}
