import React, { useEffect, useState } from "react";
import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import "./EmpProfile.scss";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import SuccessModal from "../../../../assets/elements/SuccessModal";
import { backarrowicon } from "../../../../assets/images/icons/icons";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import { Table } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { notifyWarn } from "../../../../assets/elements/Toast";
import schedulegridview from "../../../../assets/images/icons/grid-view.svg";
import schedulelistview from "../../../../assets/images/icons/list-view.svg";
// import schedulecalendarview from "../../../../assets/images/icons/calendar-view.svg";
import CustomPagination from "../../../../assets/elements/CustomPagination";
import { timescheduleicon } from "../../../../assets/images/icons/icons";
import trainerprofileicon from "../../../../assets/images/icons/trainer-profile-icon.svg";
import Calendar from "react-calendar";
import { useNavigateSearch } from "../../../../hooks/useNavigate";
import profilePic from "../../../../assets/images/icons/profile-image.svg";
import profileOptionIcon from "../../../../assets/images/icons/more.svg";
import IconButton from "../../../../assets/elements/IconButton";
import editIcon from "../../../../assets/images/icons/edit.svg";
import attachmentIcon from "../../../../assets/images/icons/attachment-icon.svg";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import PageLoading from "../../../../assets/loaders/PageLoading";
import { useLocationParams } from "../../../../hooks/useNavigate";
import moment from "moment";
import { PrimaryInput, PasswordInput } from "../../../../assets/FormElements";
import { Formik } from "formik";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../../assets/images/icons/icons";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import PermissionFunctions from "../../../../assets/functions/PermissionFunctions";
import useAuth from "../../../../hooks/useAuth";
import useConfig from "../../../../hooks/useConfig";
import useFeatureSetting from "../../../../hooks/useFeatureSetting";
import { PrimaryButton } from "../../../../assets/buttons";
import StatusModalContent from "../../../../assets/modal/StatusModalContent";
import { notifySuccess } from "../../../../assets/elements/Toast";

export default function AddSop() {
  const { updateCurrentPage } = useCurrentPage();
  const [successModal, setSuccessModal] = useState();
  const [successType, setSuccessType] = useState();
  const navigate = useNavigate();
  const [scheduleView, setScheduleView] = useState("grid");
  const [offSet, setOffSet] = useState(0);
  const navigateSearch = useNavigateSearch();
  const dayNamesShort = ["S", "M", "T", "W", "T", "F", "S"];
  const [value, onChange] = useState(new Date());
  const location = useLocation();
  // const userId = new URLSearchParams(location.search).get("user_id");
  const [pageLoading, setPageLoading] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [userSop, setUserSop] = useState([]);
  const [addScheduleModal, setAddScheduleModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const { grantPermission } = PermissionFunctions();
  const { user } = useAuth();
  const userId = user.user_id;
  const { theme } = useConfig();
  const { updateSettingContext, setting } = useFeatureSetting();
  const [statusModal, setStatusModal] = useState(false);

  const addSchema = Yup.object().shape({
    sop_no: Yup.string()
      .transform((value) => value.trim())
      .required("SOP Number is Required"),
    sop_name: Yup.string()
      .transform((value) => value.trim())
      .required("SOP Name is Required"),
    sop_revision_no: Yup.string()
      .transform((value) => value.trim())
      .required("SOP Revision Number is Required"),
    department: Yup.string().required("Description is Required"),
    frequency: Yup.string().required("Frequency is Required"),
    effective_date: Yup.string().required("Effective Date is Required"),
    workflow: Yup.string().required("Workflow is Required"),
    passing_percentage: Yup.string().required("Passing Persentage is Required"),
    minimum_read_time: Yup.string().required("Minimum Read Time is Required"),
    description: Yup.string().required("Description is Required"),
  });

  const resetPasswordSchema = Yup.object().shape({
    empEmail: Yup.string()
      .transform((value) => value.trim())
      .email("Email is invalid")
      .required("Email is required"),
    password: Yup.string()
      .transform((value) => value.trim())
      .required("Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/,
        "Password must be between 8 and 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character from @$!%*?&."
      ),
    confirmPassword: Yup.string()
      .transform((value) => value.trim())
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onResetPassword = () => {
    setResetPasswordModal(!resetPasswordModal);
  };

  const {
    getEmployeeMasters,
    getUserBatchSopByCompletionStatus,
    employeeStatusUpdateMaster,
  } = MainServices();

  const searchParamsObject = useLocationParams();
  useEffect(() => {
    updateCurrentPage({
      title: "Profile",
      subtitle: "user Details",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserDetials();
    // getUserSops();
  }, []);

  useEffect(() => {
    updateSettingContext("employee-registration");
  }, []);

  //functions
  const onScheduleView = (view) => {
    setScheduleView(view);
  };

  const onStatusModal = () => {
    setStatusModal(!statusModal);
  };

  //apis
  const getUserDetials = async () => {
    setPageLoading(true);
    const details = {
      filters: [
        {
          key: "publicId",
          eq: userId,
        },
      ],
    };
    await axios
      .all([getEmployeeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetail = resData.data ? resData.data : [];
            userDetail = userDetail[0] ? userDetail[0] : {};
            setUserDetails(userDetail);
            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting User Details");
      });
  };

  const getUserSops = async () => {
    setPageLoading(true);
    const details = {
      status: "PENDING",
      user_id: userId,
    };
    await axios
      .all([getUserBatchSopByCompletionStatus(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userSop = resData.data ? resData.data : [];
            userSop = userSop.data ? userSop.data : {};
            setUserSop(userSop);
            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting User Sops");
      });
  };

  //functions
  const onAddScheduleTraining = () => {
    setAddScheduleModal(!addScheduleModal);
  };

  const onEmployeeStatusChange = async (
    { status, email, dsPassword },
    isStatus
  ) => {
    setButtonLoading(true);
    const details = {
      status: status.value ? status.value : status,
      // email,
      ...(dsPassword && { dsPassword: dsPassword }),
    };
    await axios
      .all([
        employeeStatusUpdateMaster(
          details,
          userDetails.publicId,
          userDetails.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getUserDetials();
            setStatusModal(false);
            notifySuccess("Status updated Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in Status Update Master");
      });
  };

  return (
    <div className="profile-master-main">
      <div className="admin-popular-main">
        <SuccessModal
          modal={successModal}
          setModal={setSuccessModal}
          message="Training SOP"
          type={successType}
        />

        <div className="header">
          <p className="content-header-main">
            <span
              onClick={() => {
                navigate("/user-management");
              }}
              style={{ cursor: "pointer" }}
            >
              {backarrowicon}
            </span>
            <span className="ms-2">Profile Details</span>
          </p>
        </div>

        {pageLoading ? (
          <PageLoading />
        ) : (
          <div className="add-sop-card-flex">
            <div className="card add-sop-card-main">
              <div>
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="profile-image-main">
                      <img src={profilePic} alt="profile pic" />
                    </div>
                    <div className="profile-image-header-main">
                      <p className="m-0 profile-header_main">
                        {userDetails?.name ? userDetails?.name : ""}
                      </p>
                      <p className="m-0 profile-content-main">
                        {userDetails?.designation?.designation_name
                          ? userDetails?.designation.designation_name
                          : ""}
                      </p>
                      <p className="m-0 profile-content-main">
                        {userDetails?.employee_id
                          ? userDetails?.employee_id
                          : ""}
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex gap-2 align-items-center">
                    <IconButton
                      iconType="add"
                      title="Schedule Training "
                      onClick={() => onAddScheduleTraining("")}
                    />

                    <div className="profile-option-icon-main">
                      <img src={profileOptionIcon} alt="Option icon" />
                    </div>
                  </div> */}

                  {grantPermission("Employee_update") &&
                    setting.update_status &&
                    userDetails.status !== setting.approval_status && (
                      <div>
                        <PrimaryButton
                          title="Update Status"
                          onClick={onStatusModal}
                        />
                      </div>
                    )}
                </div>

                <Tabs
                  defaultActiveKey="basic_details"
                  id="add-sop-example"
                  className={`mb-3 custom-tabs  ${theme}`}
                >
                  <Tab eventKey="basic_details" title="Employee Information">
                    <div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="upcoming-schedule-text-main">
                            <p className="m-0">General Information</p>
                          </div>
                          {grantPermission("UserProfile_update") && (
                            <div
                              className="profile-certificate-edit-main"
                              onClick={() => {
                                navigate({
                                  pathname: "/user-management",
                                  search: `?publicId=${userDetails?.publicId}`,
                                });
                              }}
                            >
                              <span className="edit-icon-main">
                                <img src={editIcon} alt="edit icon" />
                              </span>
                              <span>Edit</span>
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">Name</p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.empName
                                  ? userDetails?.empName
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Contact Number
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.empContact
                                  ? userDetails?.empContact
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Email Address
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.empEmail
                                  ? userDetails?.empEmail
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Employee Id
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.empCode
                                  ? userDetails?.empCode
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Joining Date
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.createdAt
                                  ? moment(userDetails?.createdAt).format(
                                      "DD-MM-yyyy"
                                    )
                                  : "--"}
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">Login Id</p>
                              <p className="m-0 perinfo-content-para">
                              {userDetails?.empEmail
                                ? userDetails?.empEmail
                                : "-"}
                              </p>
                            </div>
                          </div> */}
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Discipline
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.deptartment?.deptName
                                  ? userDetails?.deptartment?.deptName
                                  : "--"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Designation
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.designation?.desgName
                                  ? userDetails?.designation?.desgName
                                  : "--"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">Role</p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.userRole?.role?.role
                                  ? userDetails?.userRole?.role?.role
                                  : "--"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Security Group
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.userSecurityGroups &&
                                  userDetails?.userSecurityGroups.length > 0 &&
                                  userDetails.userSecurityGroups.map((each) => {
                                    return (
                                      <span>
                                        {each?.securityGroup.securityGroupName
                                          ? each?.securityGroup
                                              .securityGroupName
                                          : "--"}
                                        ,{"  "}
                                      </span>
                                    );
                                  })}
                              </p>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Joining Date
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.joiningDate
                                  ? moment(userDetails?.joiningDate).format(
                                      "DD-MM-yyyy"
                                    )
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ color: "#4D5D6B" }} />
                      {/* <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="upcoming-schedule-text-main">
                            <p className="m-0">Login Details</p>
                          </div>

                          <div
                            className="profile-certificate-edit-main"
                            onClick={onResetPassword}
                          >
                            <span className="edit-icon-main">
                              <img src={editIcon} alt="edit icon" />
                            </span>
                            <span>Edit</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                           
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Password
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.password
                                  ? userDetails?.password
                                  : "***********"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                            <div className="personal-information-content-main">
                              <p className="m-0 perinfo-content-header">
                                Confirm Password
                              </p>
                              <p className="m-0 perinfo-content-para">
                                {userDetails?.password
                                  ? userDetails?.password
                                  : "***********"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ color: "#4D5D6B" }} />
                      <div className="personal-information-content-main">
                        <p className="m-0 perinfo-content-header">Attachment</p>
                        <div className="mt-2">
                          <p className="m-0 perinfo-content-para">
                            <span className="attachment-icon-main">
                              <img
                                src={attachmentIcon}
                                alt="attachments icon"
                              />
                            </span>
                            <span style={{ fontWeight: "500" }}>
                              Experiance.PDF
                            </span>
                          </p>
                          <p className="m-0 perinfo-content-para">
                            <span className="attachment-icon-main">
                              <img
                                src={attachmentIcon}
                                alt="attachments icon"
                              />
                            </span>
                            <span style={{ fontWeight: "500" }}>
                              Training Certificates.PDF
                            </span>
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </Tab>
                  {/* <Tab eventKey="upload_sop" title="Personal Information">
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="upcoming-schedule-text-main">
                          <p className="m-0">Personal Information :</p>
                        </div>

                        <div className="profile-certificate-edit-main">
                          <span className="edit-icon-main">
                            <img src={editIcon} alt="edit icon" />
                          </span>
                          <span>Edit</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="personal-information-content-main">
                            <p className="m-0 perinfo-content-header">Name</p>
                            <p className="m-0 perinfo-content-para">
                              {userDetails?.empName
                                ? userDetails?.empName
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="personal-information-content-main">
                            <p className="m-0 perinfo-content-header">
                              Contact Number
                            </p>
                            <p className="m-0 perinfo-content-para">
                              {userDetails?.empContact
                                ? userDetails?.empContact
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="personal-information-content-main">
                            <p className="m-0 perinfo-content-header">
                              Email Address
                            </p>
                            <p className="m-0 perinfo-content-para">
                              {userDetails?.empEmail ? userDetails?.empEmail : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="personal-information-content-main">
                            <p className="m-0 perinfo-content-header">
                              Address
                            </p>
                            <p className="m-0 perinfo-content-para">
                              {userDetails?.full_address
                                ? userDetails?.full_address
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                          <div className="personal-information-content-main">
                            <p className="m-0 perinfo-content-header">
                              Date Of Birth
                            </p>
                            <p className="m-0 perinfo-content-para">
                              {userDetails?.dob ? userDetails?.dob : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr style={{ color: "#4D5D6B" }} />
                      <div>
                        <div className="personal-information-content-main">
                          <p className="m-0 perinfo-content-header">
                            Attachment
                          </p>
                          <div className="mt-2">
                            <p className="m-0 perinfo-content-para">
                              <span className="attachment-icon-main">
                                <img
                                  src={attachmentIcon}
                                  alt="attachments icon"
                                />
                              </span>
                              <span style={{ fontWeight: "500" }}>
                                Aadhar_Card.PDF
                              </span>
                            </p>
                            <p className="m-0 perinfo-content-para">
                              <span className="attachment-icon-main">
                                <img
                                  src={attachmentIcon}
                                  alt="attachments icon"
                                />
                              </span>
                              <span style={{ fontWeight: "500" }}>
                                Pan_Card.PDF
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab> */}
                  {/* <Tab eventKey="questions" title="Certifications">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="upcoming-schedule-text-main">
                        <p className="m-0">All Certifications</p>
                      </div>

                      <Dropdown className="dropdown-main">
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="dropdown-content-main"
                        >
                          Short All
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="row mt-4">
                      {[...Array(9)].map((each) => {
                        return (
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div className="profile-certificate-card-main">
                              <div>
                                <p className="profile-certificate-card-header m-0">
                                  Induction Training
                                </p>
                                <p className="profile-certificate-card-subheader m-0">
                                  SOP 003
                                </p>
                              </div>
                              <hr style={{ color: "#4D5D6B" }} />
                              <div>
                                <div className="certificate-card-content-main mb-1">
                                  <span className="content-header-main">
                                    Certification Date:
                                  </span>
                                  <span className="content-para-main">
                                    20/02/2023
                                  </span>
                                </div>
                                <div className="certificate-card-content-main mb-1">
                                  <span className="content-header-main">
                                    Attempt:
                                  </span>
                                  <span className="content-para-main">
                                    2 Attempt
                                  </span>
                                </div>
                                <div className="certificate-card-content-main">
                                  <span className="content-header-main">
                                    Mark Obtained:
                                  </span>
                                  <span className="content-para-main">80%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                  <Tab eventKey="schedule" title="Schedule">
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="upcoming-schedule-text-main">
                          <p className="m-0">Upcoming Schedule</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center gap-2">
                          <Dropdown className="dropdown-main">
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="dropdown-content-main"
                            >
                              Short Upcoming
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>

                          <div
                            className={`schedule-view-icon-btn ${
                              scheduleView === "grid" &&
                              "schedule-view-active-main"
                            }`}
                            onClick={() => onScheduleView("grid")}
                          >
                            <div className="schedule-view-icon-main">
                              <img src={schedulegridview} alt="grid view" />
                            </div>
                          </div>

                          <div
                            className={`schedule-view-icon-btn ${
                              scheduleView === "list" &&
                              "schedule-view-active-main"
                            }`}
                            onClick={() => onScheduleView("list")}
                          >
                            <div className="schedule-view-icon-main">
                              <img src={schedulelistview} alt="list view" />
                            </div>
                          </div>

                          <div
                            className={`schedule-view-icon-btn ${
                              scheduleView === "calendar" &&
                              "schedule-view-active-main"
                            }`}
                            onClick={() => onScheduleView("calendar")}
                          >
                            <div className="schedule-view-icon-main">
                              <img
                                // src={schedulecalendarview}
                                alt="calendar view"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {scheduleView === "grid" && (
                        <div className="row">
                          {userSop.map((each) => (
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                              <div className="upcomig-schedule-main mt-3">
                                <div className="upcoming-schedule-tabs-main">
                                  <p className="m-0 schedule-tabs-header-main">
                                    {each?.sop_id?.sop_name}
                                  </p>

                                  <div className="d-flex align-items-center gap-2 flex-wrap">
                                    <div className="schedule-tab-main">
                                      <span>{each?.batch_id?.name}</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="time-schedule-card-main">
                                  <div>
                                    <p className="m-0 time-schedule-width-header-main">
                                      Start Time
                                    </p>
                                    <p className="m-0 time-schedule-width-content-main">
                                      {moment(
                                        each?.batch_id?.start_date
                                      ).format("DD.MM.YYYY h:mm A")}
                                    </p>
                                  </div>
                                  <div>{timescheduleicon}</div>
                                  <div>
                                    <p className="m-0 time-schedule-width-header-main">
                                      End Time
                                    </p>
                                    <p className="m-0 time-schedule-width-content-main">
                                      {moment(each?.batch_id?.end_date).format(
                                        "DD.MM.YYYY h:mm A"
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <hr style={{ background: "#4D5D6B" }} />

                                <div className="d-flex align-items-center gap-3">
                                  <div>
                                    <img
                                      src={trainerprofileicon}
                                      alt="trainer profile"
                                    />
                                  </div>
                                  <div>
                                    <span className="trainer_name">
                                      {each?.batch_id?.trainer_id?.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      {scheduleView === "list" && (
                        <div className="mt-3">
                          {userSop && userSop.length > 0 ? (
                            <div className="content">
                              <div className="table-main">
                                <Table
                                  responsive
                                  bordered
                                  className="table table-bordered"
                                >
                                  <thead className="service-table-head">
                                    <tr>
                                      <th>Sr.No</th>
                                      <th>Training Category</th>
                                      <th>SOP</th>
                                      <th>Trainer</th>
                                      <th>Training Type</th>
                                      <th>Schedule Date</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userSop.map((each, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{each?.batch_id?.name}</td>
                                          <td>{each?.sop_id?.sop_name}</td>
                                          <td>
                                            {each?.batch_id?.trainer_id?.name}
                                          </td>
                                          <td>
                                            {
                                              each?.batch_id?.trainer_id
                                                ?.trainer_type
                                            }
                                          </td>
                                          <td>
                                            {moment(
                                              each?.batch_id?.start_date
                                            ).format("DD.MM.YYYY h:mm A")}
                                          </td>
                                          <td>
                                            <span
                                              style={{
                                                color: "rgba(3, 152, 85, 1)",
                                              }}
                                            >
                                              Start
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          ) : (
                            <div className="no-data-found-main">
                              No Departments Found
                            </div>
                          )}
                          <CustomPagination
                            offSet={offSet}
                            setOffSet={setOffSet}
                            totalCount={10}
                            navigateSearch={navigateSearch}
                            getList={() => {}}
                          />
                        </div>
                      )}

                      {scheduleView === "calendar" && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                            {userSop &&
                              userSop.map((each) => (
                                <div className="upcomig-schedule-main mt-3">
                                  <div className="upcoming-schedule-tabs-main">
                                    <p className="m-0 schedule-tabs-header-main">
                                      {each?.sop_id?.sop_name}
                                    </p>

                                    <div className="d-flex align-items-center gap-2 flex-wrap">
                                      <div className="schedule-tab-main">
                                        <span>{each?.batch_id?.name}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="time-schedule-card-main">
                                    <div>
                                      <p className="m-0 time-schedule-width-header-main">
                                        Start Time
                                      </p>
                                      <p className="m-0 time-schedule-width-content-main">
                                        {moment(
                                          each?.batch_id?.start_date
                                        ).format("DD.MM.YYYY h:mm A")}
                                      </p>
                                    </div>
                                    <div>{timescheduleicon}</div>
                                    <div>
                                      <p className="m-0 time-schedule-width-header-main">
                                        End Time
                                      </p>
                                      <p className="m-0 time-schedule-width-content-main">
                                        {moment(
                                          each?.batch_id?.end_date
                                        ).format("DD.MM.YYYY h:mm A")}
                                      </p>
                                    </div>
                                  </div>

                                  <hr style={{ background: "#4D5D6B" }} />

                                  <div className="d-flex align-items-center gap-3">
                                    <div>
                                      <img
                                        src={trainerprofileicon}
                                        alt="trainer profile"
                                      />
                                    </div>
                                    <div>
                                      <span className="trainer_name">
                                        {each?.batch_id?.trainer_id?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div className="profile-calendar-main">
                              <Calendar
                                onChange={onChange}
                                value={value}
                                formatShortWeekday={(locale, value) =>
                                  dayNamesShort[value.getDay()]
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        centered
        show={addScheduleModal}
        onHide={() => onAddScheduleTraining("")}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Add Schedule Training
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body"></Modal.Body>
      </Modal>

      <div className="add-sop-card-flex">
        <Modal
          centered
          show={resetPasswordModal}
          onHide={onResetPassword}
          className="add-department-modal-main"
          selected={userDetails}
          size="md"
        >
          <Modal.Header closeButton className="add-department-modal-header">
            <Modal.Title className="add-department-modal-title">
              Reset Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-department-modal-body">
            <Formik
              initialValues={{
                empEmail: userDetails?.empEmail || "",
                password: userDetails?.password || "",
                confirmPassword: userDetails?.password || "",
              }}
              onSubmit={(values) => {
                // addEmployee(values);
              }}
              validationSchema={resetPasswordSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="form" onSubmit={handleSubmit}>
                  <div className="nk-block">
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="form-group ">
                          <label className="form-label" for="email">
                            Login ID
                            <span className="required-star"> *</span>
                          </label>
                          <div className="form-control-wrap">
                            <PrimaryInput
                              type="empEmail"
                              className="form-control"
                              id="loginId"
                              name="empEmail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.empEmail}
                              placeholder="user@gmail.com"
                              disabled
                            />
                            <CustomErrorMessage
                              name="empEmail"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group user-custom-password-field">
                          <label className="form-label" for="contact_number">
                            Password
                            <span className="required-star"> *</span>
                          </label>
                          <PasswordInput
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            name="password"
                          />
                          <CustomErrorMessage
                            name="password"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group user-custom-password-field">
                          <label className="form-label" for="confirmPassword">
                            Confirm Password
                            <span className="required-star"> *</span>
                          </label>
                          <div className="form-control-wrap">
                            <PasswordInput
                              placeholder="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              name="confirmPassword"
                            />
                            <CustomErrorMessage
                              name="confirmPassword"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-3">
                        <div className="d-flex gap-2 justify-content-end">
                          <Button
                            className="back-icon-btn-main"
                            onClick={onResetPassword}
                          >
                            <span>{squareBackIcon}</span>
                            <span className="ms-2">Back</span>
                          </Button>
                          <Button className="theme-btn-main" type="submit">
                            {buttonLoading ? (
                              <ButtonLoading />
                            ) : (
                              "Update changes"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
      <Modal
        centered
        show={statusModal}
        onHide={onStatusModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Status
          </Modal.Title>
        </Modal.Header>

        {statusModal && (
          <Modal.Body className="add-department-modal-body">
            <StatusModalContent
              setting={setting}
              statusApiFunction={onEmployeeStatusChange}
              onClose={onStatusModal}
              loading={buttonLoading}
            />
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}
