import React, { useState, useEffect } from "react";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Table } from "reactstrap";
import IconButton from "../../../assets/elements/IconButton";
import Pagination from "../../../assets/elements/CustomPagination";
import SuccessModal from "../../../assets/elements/SuccessModal";
import "./AccessRole.scss";
import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import useCurrentPage from "../../../hooks/useCurrentPage";
import accessRole_reset from "../../../assets/images/icons/reseticon.svg";
import axios from "axios";
import PageLoader from "../../../assets/loaders/PageLoading";
import MainServices from "../../../services/MainServices";
import { notifyWarn, notifySuccess } from "../../../assets/elements/Toast";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { PrimaryInput } from "../../../assets/FormElements";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import { PrimaryButton } from "../../../assets/buttons";

const allStatus = [
  { label: "All", value: "" },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

const AccessRole = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState();
  const [successType, setSuccessType] = useState();
  const [editAccessRoleModal, setEditAccessRoleModal] = useState(false);
  const [deleteAccessRoleModal, setDeleteAccessRoleModal] = useState(false);
  const [accessRoles, setAccessRoles] = useState([]);
  const [totalAccessRoles, setTotalAccessRoles] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const { updateCurrentPage } = useCurrentPage();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState("");
  const location = useLocation();
  const [selectedAccessRole, setSelectedAccessRole] = useState([]);
  const [permissionMenu, setPermissionMenu] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const { deBounce } = TimingFunctions();
  const { grantPermission } = PermissionFunctions();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});
  const [tools, setTools] = useState([]);

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const accessRoleSchema = Yup.object().shape({
    role_name: Yup.string().required("Enter Role"),
    permissions: Yup.array()
      .min(1, "Select roles")
      .required("Permission's are mandatory!"),
  });

  const {
    getRoleMasters,
    getRolePermissionMasters,
    addRolePermissionMaster,
    updateRolePermissionMaster,
    deleteRolePermissionMaster,
    getAllPermission,
    getToolsAccess,
  } = MainServices();

  useEffect(() => {
    updateCurrentPage({
      title: "Role",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTools();
    getPermissionsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setSearchString(filtersObj?.name?.name);
    setFilters(filtersObj);

    getRolePermissionMastersByStatus({
      page: pagination.page,
      limit: pagination.limit,
      filters,
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  //apis
  const getPermissionsByRole = async (filters) => {
    setModalLoading(true);
    const details = {
      filters,
      pageNumber: 1,
      pageSize: 500,
    };
    await axios
      .all([getRolePermissionMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let rolePermissions = resData.data ? resData.data : [];
            rolePermissions = rolePermissions.map((_) => _.permissionId);
            setRolePermissions(rolePermissions);
            setModalLoading(false);
          } else {
            setModalLoading(false);
          }
        } else {
          setModalLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  const getPermissionsList = async (tool) => {
    setModalLoading(true);
    const details = {
      ...(tool ? { filters: [{ key: "source", eq: tool }] } : {}),
    };
    await axios
      .all([getAllPermission(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let roles = resData.data ? resData.data : {};
            roles = roles.reduce(
              (result, item) => ({
                ...result,
                [item?.operationId || undefined]: {
                  ...item?.operation,
                  permissions: [
                    ...(result[item?.operationId]?.permissions || []),
                    {
                      ...item,
                      checked: false,
                    },
                  ],
                },
              }),
              {}
            );
            let modifedPermissions = Object.keys(roles).map((key) => {
              return {
                ...roles[key],
              };
            });
            setPermissionMenu(modifedPermissions);
            setModalLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  const getRolePermissionMastersByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 20,
      pageNumber: page || 1,
      filters,
    };
    await axios
      .all([getRoleMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let role = resData.data ? resData.data : [];
            let total = role?.length || 0;
            setTotalAccessRoles(total);
            role = role.map((_, index) => {
              return {
                ..._,
                sNo: rowsPerPage * (page - 1) + index + 1,
              };
            });
            setAccessRoles(role);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Roles");
      });
  };

  const addRolePermission = async (values) => {
    setButtonLoading(true);
    let permissions = values?.permissions || [];
    permissions = permissions.map((_) => {
      return {
        permissionId: _,
        status: "active",
        source: values.source,
      };
    });

    const details = {
      role: values?.role_name,
      permissions: permissions,
      source: values.source,
    };
    await axios
      .all([addRolePermissionMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getRolePermissionMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            handleEditClick();
            notifySuccess("Access Role Added Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in adding Access Role");
      });
  };

  const updateRolePermission = async (values) => {
    setButtonLoading(true);
    let permissions = values?.permissions || [];
    permissions = permissions.map((_) => {
      return {
        permissionId: _,
        status: "active",
        roleId: selectedAccessRole?.publicId,
        source: values.source,
      };
    });

    const details = {
      permission_data: permissions,
      roleId: selectedAccessRole?.publicId,
      source: values.source,
    };

    await axios
      .all([
        updateRolePermissionMaster(
          details,
          selectedAccessRole.publicId,
          selectedAccessRole.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            getRolePermissionMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            handleEditClick();
            notifySuccess("Access Role Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating AccessRole");
      });
  };

  const deleteRolePermission = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };
    await axios
      .all([
        deleteRolePermissionMaster(
          details,
          selectedAccessRole.publicId,
          selectedAccessRole.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getRolePermissionMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            handleDeleteClick();
            onDeleteAccessRole();
            notifySuccess("Access Role Deleted Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting Access Role");
      });
  };

  //functions
  const handleEditClick = async (selected, tool) => {
    setRolePermissions([]);
    setEditAccessRoleModal(!editAccessRoleModal);
    if (selected) {
      let filters = [
        {
          key: "roleId",
          eq: selected.publicId,
        },
        ...(tool ? [{ key: "source", eq: tool }] : []),
      ];
      if (tool) {
        await getPermissionsList(tool);
        getPermissionsByRole(filters);
      } else {
        setPermissionMenu([]);
        setRolePermissions([]);
      }
      selected.source = tool;
      setSelectedAccessRole(selected);
    } else {
      setSelectedAccessRole("");
      // setPermissionMenu([]);
      setRolePermissions([]);
    }
  };

  const onDeleteAccessRole = (selected) => {
    if (selected) {
      setSelectedAccessRole(selected);
    } else {
      setSelectedAccessRole("");
    }
    setDeleteAccessRoleModal(!deleteAccessRoleModal);
  };

  const handleCloseEditModal = () => {
    setEditAccessRoleModal(false);
  };

  const handleDeleteClick = () => {
    // setDeleteAccessRoleModal(!deleteAccessRole);
    setDeleteAccessRoleModal(!deleteAccessRoleModal);
  };

  const handleResetButton = (resetForm, setFieldValue) => {
    resetForm();
    setFieldValue("access", []);
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchTraining = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      role: { role: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 20,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const getTools = async () => {
    const details = {
      pageSize: 20,
      pageNumber: 1,
    };
    await axios
      .all([getToolsAccess(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let tools = resData.data ? resData.data : [];
            setTools(tools);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Location");
      });
  };

  return (
    <div className="admin-popular-main">
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}
        message="Feature Service"
        type={successType}
      />

      <div className="header">
        <p className="content-header-main">Access Role</p>
        <div className="d-flex gap-3">
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchTraining(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex">
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
              style={{ width: "100px" }}
            >
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {grantPermission("AccessRight_write") && (
            <IconButton
              iconType="add"
              title="Access Role"
              onClick={() => handleEditClick("")}
            />
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : accessRoles && accessRoles.length > 0 ? (
        <div className="content">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>Sr.No</th>
                  <th>Role</th>
                  <th>Description</th>
                  <th>Created At</th>
                  {grantPermission("AccessRight_update") && <th>Action</th>}
                  {grantPermission("AccessRight_delete") && <th>Delete</th>}
                </tr>
              </thead>
              <tbody>
                {accessRoles &&
                  accessRoles.map((each, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{each?.role}</td>
                        <td>{each?.description}</td>
                        <td>
                          {moment(each?._id?.createdAt).format("DD.MM.YYYY")}
                        </td>
                        {(grantPermission("AccessRight_update") ||
                          grantPermission("AccessRight_delete")) && (
                          <td className="options">
                            <div className="d-flex gap-2">
                              {grantPermission("AccessRight_update") &&
                                tools &&
                                tools.length > 0 &&
                                tools.map((eachTool) => (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleEditClick(each, eachTool.name);
                                    }}
                                  >
                                    <img src={editicon} alt="Edit icon" />
                                    <span>{eachTool.name}</span>
                                  </div>
                                ))}
                            </div>
                          </td>
                        )}
                        {grantPermission("AccessRight_update") && (
                          <td className="options">
                            <div className="d-flex gap-2">
                              {each?._id?.master ? (
                                <></>
                              ) : (
                                grantPermission("AccessRight_delete") && (
                                  <div style={{ cursor: "pointer" }}>
                                    <img
                                      src={removeicon}
                                      alt="Remove icon"
                                      onClick={() => onDeleteAccessRole(each)}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No Access Role Found</div>
      )}
      {accessRoles && accessRoles.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalAccessRoles}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        size="lg"
        show={editAccessRoleModal}
        onHide={() => handleCloseEditModal()}
        className="add-department-modal-main workflow-model"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {selectedAccessRole ? "Update " : "Add "} Access Role
          </Modal.Title>
        </Modal.Header>
        {modalLoading ? (
          <PageLoader />
        ) : (
          <Modal.Body className="add-department-modal-body">
            {permissionMenu.length > 0 ? (
              <Formik
                initialValues={{
                  role_name: selectedAccessRole?.role || "",
                  permission_data:
                    selectedAccessRole?.permissions || permissionMenu,
                  permissions: rolePermissions || [],
                  source: selectedAccessRole?.source,
                }}
                onSubmit={(values) => {
                  selectedAccessRole
                    ? updateRolePermission(values)
                    : addRolePermission(values);
                }}
                validationSchema={accessRoleSchema}
              >
                {({
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  errors,
                  touched,
                  resetForm,
                }) => (
                  <Form className="form" onSubmit={handleSubmit}>
                    <PrimaryInput
                      type="text"
                      className="form-control"
                      id="role_name"
                      name="role_name"
                      placeholder="Enter Role"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role_name}
                      disabled={selectedAccessRole}
                    />
                    <CustomErrorMessage
                      name="role_name"
                      errors={errors}
                      touched={touched}
                    />
                    <div className="content">
                      <div className="table-main">
                        <Table
                          responsive
                          bordered
                          className="table custom-table"
                        >
                          <thead className="service-table-head">
                            <tr>
                              <th>Menu</th>
                              <th className="head-operations">View</th>
                              <th className="head-operations">Create</th>
                              <th className="head-operations">Modify</th>
                              <th className="head-operations">Delete</th>
                            </tr>
                          </thead>
                          <tbody className="custom-table-body">
                            {values.permission_data &&
                              values.permission_data.length > 0 &&
                              values.permission_data.map((each, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="operation-text">
                                      {each?.operationName?.replace(/_/g, " ")}
                                    </td>
                                    {each.permissions &&
                                      each.permissions.length > 0 &&
                                      each.permissions.map((_) => {
                                        console.log(each);
                                        console.log(_);
                                        return (
                                          <td className="options">
                                            <input
                                              type="checkbox"
                                              checked={values?.permissions?.includes(
                                                _.publicId
                                              )}
                                              onChange={() => {
                                                let data =
                                                  values?.permissions || [];
                                                if (
                                                  data.includes(_?.publicId)
                                                ) {
                                                  data = data.filter((e) => {
                                                    return (
                                                      e !== `${_?.publicId}`
                                                    );
                                                  });
                                                } else {
                                                  data.push(_?.publicId);
                                                }

                                                setFieldValue(
                                                  "permissions",
                                                  data
                                                );
                                              }}
                                            />
                                          </td>
                                        );
                                      })}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        <CustomErrorMessage
                          name="permissions"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex gap-2">
                        {selectedAccessRole ? (
                          <></>
                        ) : (
                          <>
                            <Button
                              className="back-icon-btn-main"
                              onClick={() =>
                                handleResetButton(resetForm, setFieldValue)
                              }
                            >
                              <img src={accessRole_reset} />
                              <span className="ms-2">Reset</span>
                            </Button>
                          </>
                        )}

                        <PrimaryButton
                          className="theme-btn-main"
                          type="submit"
                          title={
                            buttonLoading ? (
                              <ButtonLoading />
                            ) : selectedAccessRole ? (
                              "Update"
                            ) : (
                              "Submit"
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <p className="text-center">No Opertaions Found</p>
            )}
          </Modal.Body>
        )}
      </Modal>
      <Modal
        centered
        show={deleteAccessRoleModal}
        onHide={onDeleteAccessRole}
        className="add-department-modal-main role-delete-model"
        selected={selectedAccessRole}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Role
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <p>
            Do you want to delete this Role{" "}
            <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
              {selectedAccessRole?.role}
            </span>
            ?
          </p>
          <div className="mt-3">
            <div className="d-flex gap-2">
              <Button
                className="back-icon-btn-main"
                onClick={onDeleteAccessRole}
              >
                <span>{squareBackIcon}</span>
                <span className="ms-2">Back</span>
              </Button>
              <Button className="theme-btn-main" onClick={deleteRolePermission}>
                {buttonLoading ? <ButtonLoading /> : "Delete"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AccessRole;
