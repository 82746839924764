import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { squareBackIcon } from "../../assets/images/icons/icons";
import EachDragItem from "../../assets/dragDrop/EachDragItem";
import { Form, InputGroup } from "react-bootstrap";
import searchicon from "../../assets/images/icons/search-normal.svg";
import { Formik } from "formik";
import { PrimaryInput } from "../../assets/FormElements";
import Cross from "../../assets/images/icons/cross.svg";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import CustomErrorMessage from "../../assets/elements/ErrorMessage";
import StrictModeDroppable from "../../assets/dnd/StrictModeDroppable";
import MainServices from "../../services/MainServices";
import ItemsDroppable from "../../assets/dragDrop/ItemsDroppable";
import TimingFunctions from "../../assets/functions/TimingFunctions";
import AsyncSelect from "react-select/async";
import axios from "axios";

export default function IsWorkflowSlug({ workflowDataSet, onManageWorkflow }) {
  const { getEmployeeMasters, getWorkflowGroup } = MainServices();
  const [employees, setEmployees] = useState([]);
  const { deBounce } = TimingFunctions();
  const [workflowGroups, setWorkflowGroups] = useState([]);

  useEffect(() => {
    getEmployeesByDiscipline();
    getWorkflowGroupMaster();
  }, []);

  //apis
  const getEmployeesByDiscipline = async (filters) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      filters: [...(filters ? filters : []), { key: "status", eq: "active" }],
      source: "calibration",
    };
    getEmployeeMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.empName,
            value: each.publicId,
          }));

          setEmployees(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWorkflowGroupMaster = async (filters, search) => {
    const details = {
      filters,
      status: "active",
      source: "calibration",
    };
    let results = await axios
      .all([getWorkflowGroup(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflowGroups = resData.data ? resData.data : [];
            workflowGroups = workflowGroups.map((_) => {
              return {
                ..._,
                label: _?.workflowGroupName,
                value: _?.publicId,
              };
            });
            workflowGroups.unshift({
              label: "Select Workflow Group",
              value: "",
            });
            !search && setWorkflowGroups(workflowGroups);
            return workflowGroups;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting workflow groups");
      });
    return results;
  };

  //functions
  const onDragItem = ({ item, setFieldValue, workflow, values }) => {
    let workflows = values.workflows || [];
    workflows = workflows.map((eachW) => {
      if (eachW.workflow === workflow) {
        let users = eachW.users || [];
        let match = users.find((each) => each.value === item.value);
        if (!match) {
          users.push(item);
        }
        return {
          ...eachW,
          users,
        };
      }
      return eachW;
    });
    setFieldValue(workflows);
  };

  const onDragEnd = (result, set, values) => {
    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) {
      return;
    }

    // If the drag type is 'SECTION'
    const update_values = reorder(
      values.workflows,
      source.index,
      destination.index
    );
    set("workflows", update_values);
    // If the drag type is 'ITEM' within the same section
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const searchemployee = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
  };

  const onSearchFilter = (e) => {
    getEmployeesByDiscipline([{ key: "empName", iLike: e.target.value }]);
  };

  const onSearchWorkflowGroupsDebounce = (inputValue, callback) => {
    deBounce(() => onSearchWorkflowGroup(inputValue, callback), 1000);
  };

  const onSearchWorkflowGroup = async (inputValue, callback) => {
    if (!inputValue) {
      callback(getWorkflowGroupMaster);
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getWorkflowGroupMaster(filter, true);
    callback(options);
  };

  return (
    <div>
      <div className="admin-popular-main">
        <div className="d-flex justify-content-end">
          <InputGroup
            style={{
              width: "auto",
              border: "0.5px solid #000",
              borderRadius: "5px",
            }}
          >
            <Form.Control
              placeholder="Search User"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchemployee(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>
        </div>
      </div>
      <Formik
        initialValues={{
          workflows: workflowDataSet?.workflows,
          workflow: "",
          workflowGroup: "",
        }}
        onSubmit={(m_values) => {
          workflowDataSet?.setFieldValue("workflows", m_values.workflows);
          onManageWorkflow({});
        }}
        key="workflow-custom-modal"
      >
        {({
          handleSubmit: m_submit,
          setFieldValue: m_set,
          values: m_values,
          handleChange: m_change,
          handleBlur: m_blur,
          errors: m_errors,
          touched: m_touched,
        }) => (
          <>
            <div>
              <div class="form-group">
                <label class="form-label" for="uomId">
                  Group Name
                  <span style={{ color: "#f00" }}> *</span>
                </label>
                <div class="form-control-wrap">
                  <AsyncSelect
                    className="sop-custom-form-select"
                    id="workflowGroup"
                    name="workflowGroup"
                    placeholder="Select..."
                    onChange={(e) => {
                      m_set("workflowGroup", e);
                      m_set("workflows", e?.workflowData && e?.workflowData);
                    }}
                    onBlur={m_blur}
                    value={m_values.workflowGroup}
                    options={workflowGroups}
                    defaultOptions={workflowGroups}
                    loadOptions={onSearchWorkflowGroupsDebounce}
                  />
                  <CustomErrorMessage
                    name="workflowGroup"
                    errors={m_errors}
                    touched={m_touched}
                  />
                </div>
                {console.log(m_values)}
              </div>
              <div className="form-group my-2">
                <label className="form-label" for="passing_percentage">
                  Workflow Name
                </label>
                <div className="form-control-wrap">
                  <div className="d-flex gap-2 ">
                    <PrimaryInput
                      type="text"
                      className="form-control"
                      id="workflow"
                      name="workflow"
                      onChange={m_change}
                      onBlur={m_blur}
                      value={m_values.workflow}
                      placeholder="Enter"
                    />
                    <Button
                      onClick={() => {
                        let workflows = m_values.workflows || [];
                        let data = m_values.workflows || [];
                        if (m_values.workflow) {
                          let isthere = false;
                          data.forEach((each) => {
                            if (
                              m_values.workflow.toLowerCase() ===
                              each.workflow.toLowerCase()
                            ) {
                              isthere = true;
                            }
                          });
                          if (!isthere) {
                            workflows.push({
                              workflow: m_values.workflow.toLowerCase(),
                              users: [],
                            });
                          }
                        }
                        m_set("workflows", workflows);
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <CustomErrorMessage
                    name="passing_percentage"
                    errors={m_errors}
                    touched={m_touched}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex gap-2" style={{ width: "100%" }}>
              <div
                style={{
                  width: "49%",
                  height: 500,
                  overflowY: "scroll",
                }}
              >
                <h6 className="mt-3">WorkFlows</h6>
                <div className="d-flex gap-2 mb-3">
                  <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, m_set, m_values)}
                  >
                    <StrictModeDroppable
                      droppableId="workflows"
                      direction="vertical"
                    >
                      {(sectionsProvided) => (
                        <div
                          style={{
                            width: "100%",
                          }}
                          {...sectionsProvided.droppableProps}
                          ref={sectionsProvided.innerRef}
                        >
                          {console.log(m_values)}
                          {m_values.workflows &&
                            m_values.workflows.length > 0 &&
                            m_values.workflows.map((section, sectionIndex) => (
                              <Draggable
                                key={section.workflow}
                                draggableId={section.workflow}
                                index={sectionIndex}
                                style={{ width: "100%" }}
                              >
                                {(sectionProvided) => (
                                  <div
                                    {...sectionProvided.draggableProps}
                                    {...sectionProvided.dragHandleProps}
                                    ref={sectionProvided.innerRef}
                                  >
                                    <ItemsDroppable
                                      onDrag={(props) =>
                                        onDragItem({
                                          ...props,
                                          workflow: section.workflow,
                                          values: m_values,
                                        })
                                      }
                                      setFieldValue={m_set}
                                      dataType="Available"
                                    >
                                      <div className="each-section my-3">
                                        <div className="d-flex justify-content-between">
                                          <h6
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {sectionIndex + 1}.
                                            {section.workflow}
                                          </h6>
                                          <div>
                                            <img
                                              alt="Cross"
                                              src={Cross}
                                              className="delete-topic"
                                              onClick={() => {
                                                let data =
                                                  m_values.workflows || [];
                                                data = data.filter(
                                                  (each, i) =>
                                                    i !== sectionIndex
                                                );
                                                m_set("workflows", data);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="users mt-2 px-3">
                                          {section.users &&
                                            section.users.length > 0 &&
                                            section.users.map((user, subI) => (
                                              <div className="each-user d-flex justify-content-between mt-2">
                                                <div>
                                                  <span>{subI + 1}.</span>
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {user.label}
                                                  </span>
                                                </div>
                                                <div>
                                                  <img
                                                    alt="Cross"
                                                    src={Cross}
                                                    className="delete-topic"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      let data =
                                                        m_values?.workflows ||
                                                        [];
                                                      let users =
                                                        data[sectionIndex]
                                                          .users || [];
                                                      users = users.filter(
                                                        (each, i) => subI !== i
                                                      );
                                                      data[sectionIndex].users =
                                                        users;
                                                      m_set("workflows", data);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </ItemsDroppable>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {sectionsProvided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                </div>
              </div>

              <div className="available" style={{ width: "49%" }}>
                <ItemsDroppable
                  onDrag={onDragItem}
                  setFieldValue={workflowDataSet?.setFieldValue}
                  dataType="Available"
                  style={{ height: 500 }}
                >
                  <h5>Users</h5>
                  {employees &&
                    employees.length > 0 &&
                    employees.map((_, index) => (
                      <EachDragItem props={_} type="availble" />
                    ))}
                </ItemsDroppable>
              </div>
            </div>

            <div className="mt-3">
              <div className="d-flex gap-2">
                <Button
                  className="back-icon-btn-main"
                  onClick={() => {
                    onManageWorkflow({});
                  }}
                >
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <Button className="theme-btn-main" onClick={m_submit}>
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
